import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LazyVideo from "../components/videoLazyHome"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <main className="home split">
      <LazyVideo src="/videos/home.mp4"></LazyVideo>

      <section className="home__content large center">
        <h1>Set up your<br/> RH Logic</h1>
        <p>The RH Logic is made to enhance your performance by providing maximum support and relief. This guide helps you set up the chair and adjust it to your body in ten easy steps.</p>
        <AniLink cover bg="#6F5C61" to="/chapters/chapter_1" className="button">Let's get started</AniLink>
      </section>      
    </main>
  </Layout>
)

export default IndexPage
