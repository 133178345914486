import React from "react"

export default class LazyVideo extends React.Component {
  state = {
    loading: true
  };
  componentDidMount() {
    if (this.refs.video) {
      this.refs.video.addEventListener("loadeddata", () => {
        this.setState({ loading: false });

        let videoElem = document.getElementsByClassName('video__video')[0];
        setTimeout(function() {
          videoElem.play();
        },200);
      });
    }
  }

  render() {
    return(
      <div className="video video--lazy">
        <div className="loader"></div>
        <video
          ref="video"
          className="video__video"
          autoPlay muted loop playsInline
          style={{
            opacity: this.state.loading ? 0 : 1
          }}
        >  
          <source src={this.props.src} type="video/mp4" />
        </video>
      </div>

      /* ————————
        Video scripts
        Compress: ffmpeg -i fg-blog.mov -vcodec h264 -acodec aac -strict -2 "fg-blog.mp4"
        Compress and Crop*: ffmpeg -i input.ext -vcodec h264 -acodec aac -strict -2 -vf "crop=1440:1080:240:0,scale=1024:768" "output.mp4"
        Compress and Crop* and Mute: ffmpeg -i original-1080p.mp4 -ss 00:00:13 -t 00:00:25 -vcodec h264 -an -strict -2 -vf "crop=1440:1080:240:0,scale=800:600" "chapter-1.mp4"

        * Based on a 1080p video and scropped to 4:3 format
      ———————— */
    )
  }
}
